const lightbox = document.querySelector('.js-lightbox');
let fullImage, videoEmbed;
if (lightbox) {
  fullImage = lightbox.querySelector('.js-full-image');
  videoEmbed = lightbox.querySelector('.js-video-embed');
}

// opening the lightbox with either a video or image
export const openLightbox = (mediaType, src) => {
  if (mediaType === 'video') {
    videoEmbed.src = src.replace('watch?v=', 'embed/');
    videoEmbed.style.display = 'block';
    fullImage.style.display = 'none';
  } else if (mediaType === 'image') {
    fullImage.src = src;
    fullImage.style.display = 'block';
    videoEmbed.style.display = 'none';
  }
  lightbox.style.display = 'flex';
};

// Add click event listener to each image
export function addLightboxEventListeners() {
  const imgElements = document.querySelectorAll('.js-lightbox-image-trigger');

  if (imgElements) {
    imgElements.forEach((img) => {
      img.addEventListener('click', function () {
        const liveStreamIFrame = document.querySelector(
          '.js-youtube-stream-player'
        );
        if (liveStreamIFrame) {
          if (liveStreamIFrame.style.display !== 'none') {
            liveStreamIFrame.contentWindow.postMessage(
              JSON.stringify({ event: 'command', func: 'pauseVideo' }),
              '*'
            );
          }
        }
        const videoUrl = this.dataset.video;
        if (videoUrl) {
          openLightbox('video', videoUrl);
        } else {
          openLightbox('image', this.src);
        }
      });
    });
  }
}

addLightboxEventListeners();

// Close lightbox when clicking outside the content
if (lightbox) {
  lightbox.addEventListener('click', () => {
    videoEmbed.src = '';
    fullImage.src = '';
    lightbox.style.display = 'none';
  });
}
