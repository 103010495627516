export default class ModalHandler {
  constructor(modalSelector) {
    this.modal = document.querySelector(modalSelector);
    this.init = this.init.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    if (this.modal) {
      this.init();
    }
  }

  showModal() {
    if (httApp.isAnyPopupActive) {
      return;
    }

    httApp.isAnyPopupActive = true;
    httApp.exitPopupDisabled = true;

    document.body.classList.add('modal-open');
    this.modal.classList.add('on');
  }

  hideModal() {
    httApp.isAnyPopupActive = false;
    httApp.exitPopupDisabled = false;
    document.body.classList.remove('modal-open');

    if (this.modal.querySelector('.js-success-video-object') !== null) {
      this.modal
        .querySelector('.js-success-video-object')
        .contentWindow.postMessage('{"method":"pause"}', '*');
    }

    this.modal.classList.remove('on');
  }

  init() {
    this.modal.querySelectorAll('.js-exit-popup-close').forEach((element) => {
      element.addEventListener('click', this.hideModal);
    });
  }
}
