import {
  processEmailCapture,
  getRecaptchaToken,
  smFunc,
  isUserLoggedIn,
} from '@/core/thinkhuge';
import { fetchAndRedirectTR } from '@/core/traderoom';
import { randomString } from '@/core/utils';
import { validateForm, showSystemError } from '@/helpers/form-validation';
import { sendGAevent } from '@/core/analytics.js';
import { modalOff, modalOn, redirectPage } from '@/helpers/form-dom';
import {
  loadAppleAuth,
  loadFacebookAuth,
  loadGoogleAuth,
} from '@/helpers/socialLogin';
import { formManager } from '@/global.js';
import NiceSelect from 'nice-select2/dist/js/nice-select2';

/*
// popup state
 */
const registerCheckComplete = false;
const registeredUserEmail = '';
let loginCheckComplete = false;

/*
// other state
 */
let registerAffiliateCheckComplete = false;
let forgotPwCheckComplete = false;

/*
// popup container node
 */
const popup = document.querySelector('.js-popup');

/*
// tabs nodes
 */
const registerTab = popup.querySelector('.js-register');
const loginTab = popup.querySelector('.js-login');
const landingSuccessTab = popup.querySelector('.js-landing-success');
const forgotTab = popup.querySelector('.js-forgot');

const allTabs = [registerTab, loginTab, landingSuccessTab, forgotTab];

/*
// other nodes
 */
const spinner = document.querySelector('.js-popup-spinner');
const registerForm = popup.querySelector('.js-register-form');
const registerHeading = popup.querySelector('.js-popup-register-heading');
const registerSubheading = popup.querySelector('.js-popup-register-subheading');
const socialSeparatorText = document.querySelector('.js-social-separator-text');

/*
// forms
 */
const navbarLoginForm = document.getElementById('navbar_loginform');
// const navbarRegisterForm = document.getElementById('navbar_regform');
const navbarRegisterAffiliateForm =
  document.getElementById('navbar_regaffform');
const navbarForgotPasswordForm = document.getElementById('navbar_forgotpwform');

/*
// Element event listeners
 */
document.querySelectorAll('.js-login-link').forEach((e) =>
  e.addEventListener('click', (e) => {
    e.preventDefault();
    modalOn(popup);
    loginOpenTab();
  })
);

document.querySelectorAll('.js-grab-email').forEach((btn) =>
  btn.addEventListener('click', (event) => {
    event.preventDefault();
    const closestInput = btn
      .closest('.form-group')
      .querySelector('.form-input.email');
    const emailValue = closestInput.value;
    document.querySelector('.js-popup .js-registration-email').value =
      emailValue;
  })
);

document.querySelectorAll('.js-register-link').forEach((link) => {
  link.addEventListener('click', registerLinkHandler);
});

if (document.querySelector('.js-reset-ok')) {
  document.querySelector('.js-reset-ok').addEventListener('click', () => {
    modalOff(popup, registerCheckComplete);
  });
}

document.querySelectorAll('.popup-close').forEach((e) =>
  e.addEventListener('click', (e) => {
    e.preventDefault();
    modalOff(popup, registerCheckComplete);
  })
);

document.querySelectorAll('.js-forgot-pw').forEach((element) => {
  element.addEventListener('click', () => {
    forgotPwOpenTab();
  });
});

/*
// Form event listeners
 */
navbarLoginForm.addEventListener('submit', function (e) {
  submitLoginForm(e);
});

// navbarRegisterForm.addEventListener('submit', function (e) {
//   submitRegisterForm(e);
// });

// navbarRegisterForm.addEventListener(
//   'change',
//   () => {
//     sendGAevent('eReg-click', 'reg-started', window.location.pathname);
//   },
//   { once: true }
// );

navbarRegisterAffiliateForm &&
  navbarRegisterAffiliateForm.addEventListener('submit', function (e) {
    submitRegisterAffiliateForm(e);
  });

navbarForgotPasswordForm.addEventListener('submit', function (e) {
  submitForgotPassword(e);
});

/*
  Social event listeners
 */
loadGoogleAuth(handleGoogleSignInResponse, () => {
  const btn = loginTab.querySelector('.googleSignInButton');
  window.google.accounts.id.renderButton(btn, {
    locale: 'en-GB',
    // eslint-disable-next-line camelcase
    click_listener: googleSocialLogin,
  });
});

loginTab.querySelector('.facebookButton')?.addEventListener('click', (e) => {
  e.preventDefault();
  sendGAevent('ext-cta', 'social-facebook', window.location.pathname);
  facebookSocialLogin();
});

loginTab.querySelector('.appleButton')?.addEventListener('click', (e) => {
  e.preventDefault();
  sendGAevent('ext-cta', 'social-apple', window.location.pathname);

  formManager.appleCallbackSuccess = (details) =>
    socialSuccessLoginCallback(details, 'apple');
  formManager.appleCallbackFailure = () => toggleSpinner(false);
  appleSocialLogin();
});

/*
// Helper Functions
 */
function toggleSpinner(enable = true) {
  spinner.classList.toggle('hidden', !enable);
}

function switchActiveTab(newTab = false) {
  for (const tab of allTabs) {
    if (tab === newTab) continue;

    tab.classList.toggle('active', false);
  }

  if (newTab) newTab.classList.toggle('active', true);
}

function loggedUserRouter(grabSource = 'loggedinusertraderoom') {
  grabEmail(grabSource);
  toggleSpinner();
  fetchAndRedirectTR()
    .then()
    .catch(() => {
      toggleSpinner(false);
    });
}

function grabEmail(grabSource = '') {
  if (!grabSource) return;

  let email = registeredUserEmail;

  // take email from logged user information
  if (!email) {
    if (
      isUserLoggedIn() &&
      'email' in httApp.userInfo &&
      httApp.userInfo.email !== ''
    ) {
      email = httApp.userInfo.email;
    }
  }

  if (!email) return;

  processEmailCapture(email, grabSource);
}

function landingSuccessOpenTab(type, grabSource) {
  landingSuccessTab.dataset.type = type;

  // if (landingWebinarCta) {
  //   landingWebinarCta.dataset.type = type;
  // }

  grabEmail(grabSource);

  // disabled 26.05.2022
  // popup.classList.remove('hide-right');
  popup.classList.add('hide-right');
  popup.classList.add('hide-steps');

  if (type === 'tradingPlan') {
    smFunc.getJson('/app/downloads/process/', 'POST', {}).then((data) => {
      if (data.result === 'success') {
        window.location.assign(data.download);
      }
    });
  }

  switchActiveTab(landingSuccessTab);
}

/*
// Registration Functions
 */
// async function submitRegisterForm(event) {
//   event.preventDefault();
//   if (registerCheckComplete === true) return;
//   toggleSpinner();
//
//   const form = navbarRegisterForm,
//     username = form.querySelector("input[name='username']").value,
//     email = form.querySelector("input[name='email']").value,
//     password = form.querySelector("input[name='password']").value,
//     fullname = form.querySelector("input[name='fullname']").value,
//     avatar = form.querySelector("input[name='avatar']").value,
//     social = form.querySelector("input[name='social']").value,
//     phoneCC = form.querySelector("select[name='phoneCc']").value,
//     phone = form.querySelector("input[name='phone']").value,
//     tos = form.querySelector("input[name='tos']").checked ? 1 : 0,
//     ios = document.getElementById('ios').value,
//     regflag = document.querySelector("input[name='regflag']")?.value,
//     customAP = document.querySelector("input[name='customAP']")?.value,
//     skipAP = document.querySelector("input[name='skipAP']")?.value,
//     submitBtn = form.querySelector("button[name='submit_btn']"),
//     submitButtonTxt = submitBtn.innerHTML,
//     regUrl = window.location.pathname,
//     errorMsg = form.querySelector('#createAccountErrMsg'),
//     action = event.target.dataset.action;
//
//   let redirect = form.querySelector("input[name='register_redirect']").value;
//   if (event.target.dataset.redirect) {
//     redirect = event.target.dataset.redirect;
//   }
//
//   errorMsg.innerHTML = '';
//   errorMsg.classList.remove('error');
//
//   submitBtn.setAttribute('disabled', 'disabled');
//   submitBtn.innerHTML = 'Please wait&hellip;';
//
//   function resetSubmitButton() {
//     submitBtn.innerHTML = submitButtonTxt;
//     submitBtn.removeAttribute('disabled');
//   }
//
//   if (!validateForm(form)) {
//     sendGAevent('eReg-error', 'field-error', window.location.pathname);
//     resetSubmitButton();
//     toggleSpinner(false);
//     return;
//   }
//
//   if (!tos) {
//     sendGAevent('eReg-error', 'tos-uncheck', window.location.pathname);
//     showSystemError(errorMsg, 'Please accept the terms of service to continue');
//     resetSubmitButton();
//     toggleSpinner(false);
//     return;
//   }
//
//   const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);
//
//   const timezone = new Date().getTimezoneOffset();
//
//   smFunc
//     .getJson('/app/auth/process/?do=register', 'POST', {
//       username,
//       email,
//       password,
//       fullname,
//       avatar,
//       social,
//       phoneCC,
//       phone,
//       tos,
//       ios,
//       regflag,
//       customAP,
//       skipAP,
//       timezone,
//       recaptchaToken,
//       redirect,
//       regUrl,
//     })
//     .then((data) => {
//       if (data.result === 'success') {
//         sendGAevent('eReg-click', 'reg-completed', window.location.pathname);
//
//         registerCheckComplete = true;
//         registeredUserEmail = email;
//
//         switch (action) {
//           case 'affiliate-program':
//             modalOff(popup, registerCheckComplete);
//             window.location.reload();
//             break;
//           case 'courses':
//             modalOff(popup, registerCheckComplete);
//             redirectPage(redirect, false);
//             break;
//           case 'monitor':
//             landingSuccessOpenTab('monitor', 'registeredmonitor');
//             break;
//           case 'tradingPlan':
//             landingSuccessOpenTab('tradingPlan', 'registered-trading-plan');
//             break;
//           case 'chartPatternSeries':
//             modalOff(popup, registerCheckComplete);
//             redirectPage(false, false);
//             break;
//           default:
//             loggedUserRouter('registeredtraderoom');
//             break;
//           // disabled 26.05.2022
//           // default:
//           //   loggedUserRouter('registeredwebinar');
//           //   break;
//         }
//       } else if (data.result === 'fail') {
//         showSystemError(errorMsg, data.reason);
//       }
//     })
//     .catch((e) => {
//       sendGAevent('eReg-error', 'error-processing', window.location.pathname);
//       showSystemError(errorMsg, 'Error processing form');
//       console.error(e);
//     })
//     .finally(() => {
//       resetSubmitButton();
//       toggleSpinner(false);
//     });
// }

async function submitRegisterAffiliateForm(e) {
  if (registerAffiliateCheckComplete === true) return;

  e && e.preventDefault();

  const form = navbarRegisterAffiliateForm,
    username = form.querySelector("input[name='username']").value,
    email = form.querySelector("input[name='email']").value,
    password = form.querySelector("input[name='password']").value,
    fullname = form.querySelector("input[name='fullname']").value,
    website = form.querySelector("input[name='website']").value,
    // phoneCC = form.querySelector("select[name='phoneCc']").value,
    // phone = form.querySelector("input[name='phone']").value,
    submitBtn = form.querySelector("button[name='submit_btn']"),
    submitButtonTxt = submitBtn.innerHTML,
    errorMsg = form.querySelector('#createAccountErrMsg');

  errorMsg.innerHTML = '';
  errorMsg.classList.remove('error');

  submitBtn.setAttribute('disabled', 'disabled');
  submitBtn.innerHTML = 'Please wait&hellip;';

  function resetSubmitButton() {
    submitBtn.innerHTML = submitButtonTxt;
    submitBtn.removeAttribute('disabled');
  }

  const valid = validateForm(form);

  if (valid) {
    const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

    const timezone = new Date().getTimezoneOffset();

    smFunc
      .getJson('/app/auth/process/?do=register', 'POST', {
        username,
        email,
        password,
        fullname,
        website,
        tos: '1',
        timezone,
        customAP: 'affiliate',
        recaptchaToken,
      })
      .then((data) => {
        if (data.result === 'success') {
          registerAffiliateCheckComplete = true;
          window.location.reload();
        } else if (data.result === 'fail') {
          showSystemError(errorMsg, data.reason);
          resetSubmitButton();
        }
      })
      .catch((e) => {
        showSystemError(errorMsg, 'Error processing form');
        resetSubmitButton();
        console.error(e);
      });
  } else {
    resetSubmitButton();
  }
}

function registerOpenTab(action, redirect) {
  const headings = {
    default: 'Start learning how to trade today!',
    academy: 'Want more videos?',
    liveStreams: 'Join our next live stream today',
  };
  const subheadings = {
    freeTrial:
      'Create your free account and unlock access now. No credit card required.',
    webinar: 'Create your account and book your webinar slot',
    monitor: 'Create your account to enter our prize draw',
    courses: 'Create your account to save your course progress',
    tradingPlan: 'Create your account to download our free trading plan',
    academy: 'Join now',
    liveStreams: 'Join now',
    chartPatternSeries: 'Create your account and unlock course videos',
    pricing: 'Get access to streams now',
    trackATrader: 'Join now to access TrackATrader',
  };

  socialSeparatorText.innerText = 'Or register using';

  switch (action) {
    case 'academy': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerHeading.innerText = headings.academy;
      registerSubheading.innerText = subheadings.academy;

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });
      break;
    }
    case 'live-streams': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerHeading.innerText = headings.liveStreams;
      registerSubheading.innerText = subheadings.liveStreams;

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });
      break;
    }
    case 'affiliate-program': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');
      registerForm.setAttribute('data-action', 'affiliate-program');
      break;
    }
    case 'courses':
    case 'monitor':
    case 'tradingPlan': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');
      popup.classList.add(
        ['monitor', 'tradingPlan'].includes(action) ? 'hide-login' : null
      );

      registerSubheading.innerText = subheadings[action];

      registerForm.setAttribute('data-action', action);
      registerForm.setAttribute('data-redirect', redirect);

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', redirect);
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', redirect);
      });

      break;
    }
    case 'chartPatternSeries': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerSubheading.innerText = subheadings[action];

      registerForm.setAttribute('data-action', action);
      registerForm.setAttribute('data-redirect', false);

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', false);
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', false);
      });

      break;
    }
    case 'tradingCompetition': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerSubheading.innerText = subheadings[action];

      registerForm.setAttribute('data-action', action);
      registerForm.setAttribute('data-redirect', true);

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', true);
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', true);
      });

      break;
    }
    case 'trackATrader': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerSubheading.innerText = subheadings.trackATrader;

      registerForm.setAttribute('data-action', action);
      registerForm.setAttribute('data-redirect', true);

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', true);
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', true);
      });

      break;
    }
    case 'pricing': {
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerSubheading.innerText = subheadings[action];

      registerForm.setAttribute('data-action', action);
      registerForm.setAttribute('data-redirect', false);

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', false);
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.setAttribute('data-action', action);
        element.setAttribute('data-redirect', false);
      });

      break;
    }
    default:
      popup.classList.add('hide-right');
      popup.classList.add('hide-steps');

      registerHeading.innerText = headings.default;
      registerSubheading.innerText = subheadings.freeTrial;

      registerTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });

      loginTab.querySelectorAll('.js-register-link').forEach((element) => {
        element.removeAttribute('data-action');
        element.removeAttribute('data-redirect');
      });
      break;
  }

  switchActiveTab(registerTab);
}

export function registerLinkHandler(event) {
  if (!event) {
    console.error('Event is undefined or null.');
    return;
  }

  // Prevent the default action if necessary, for example, if it's a link
  event.preventDefault();

  // If there are multiple exit popups, iterate over them and close each one if it's open
  const exitPopups = document.querySelectorAll('.js-exit-popup');
  const registerPopup = document.querySelectorAll('.js-popup');

  exitPopups.forEach((popup) => {
    if (popup.classList.contains('on')) {
      const successVideoObject = document.querySelector(
        '.js-success-video-object'
      );

      if (successVideoObject !== null) {
        successVideoObject.contentWindow.postMessage('{"method":"pause"}', '*');
      }

      httApp.isAnyPopupActive = false;
      setTimeout(() => document.body.classList.remove('modal-open'), 50);
      setTimeout(() => popup.classList.remove('on'), 25);
    }
  });

  modalOn(registerPopup[0]);

  const { action, redirect } = event.target.dataset;

  if (isUserLoggedIn()) {
    switch (action) {
      case 'monitor':
        landingSuccessOpenTab('monitor', 'loggedinusermonitor');
        break;
      case 'tradingPlan':
        landingSuccessOpenTab('tradingPlan', 'loggedin-user-trading-plan');
        break;
      default:
        loggedUserRouter();
        break;
    }
  } else {
    registerOpenTab(action, redirect);
  }
}

/*
// Login Functions
 */
async function submitLoginForm(e) {
  if (loginCheckComplete === true) return;
  toggleSpinner();
  e && e.preventDefault();

  const form = navbarLoginForm,
    username = form.querySelector("input[name='username']").value,
    password = form.querySelector("input[name='password']").value,
    redirect = form.querySelector("input[name='login_redirect']").value,
    errDiv = form.querySelector('#loginAccountErrMsg'),
    submitButton = form.querySelector('button'),
    submitButtonTxt = submitButton.innerText;

  errDiv.innerHTML = '';
  errDiv.classList.remove('error');

  submitButton.innerHTML = 'Please wait&hellip;';
  submitButton.disabled = true;
  submitButton.classList.add('disabled');

  function resetSubmitButton() {
    submitButton.innerText = submitButtonTxt;
    submitButton.disabled = false;
    submitButton.classList.remove('disabled');
  }
  const valid = validateForm(form);

  if (valid) {
    const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

    smFunc
      .getJson('/app/auth/process/?do=authCheck', 'POST', {
        username,
        password,
        recaptchaToken,
        redirect,
      })
      .then((data) => {
        if (data.result === 'success') {
          loginCheckComplete = true;
          redirectPage(redirect, data.tr_access);
        } else if (data.result === 'fail') {
          showSystemError(errDiv, data.reason);
          resetSubmitButton();
          toggleSpinner(false);
        }
      })
      .catch((e) => {
        showSystemError(errDiv, 'Error processing form');
        resetSubmitButton();
        toggleSpinner(false);
        console.error(e);
      });
  } else {
    resetSubmitButton();
    toggleSpinner(false);
  }
}

function loginOpenTab() {
  popup.classList.remove('hide-steps');
  socialSeparatorText.innerText = 'Or login using';

  switchActiveTab(loginTab);
  loginTab.querySelectorAll('.js-view-password-toggle').forEach((toggle) => {
    toggle.addEventListener('click', () => {
      const targetForm = loginTab;
      const field = targetForm.querySelector("input[name='password']");
      const active = targetForm.querySelector(
        '.js-view-password-toggle.active'
      );
      const hidden = targetForm.querySelector(
        '.js-view-password-toggle.hidden'
      );
      if (field.type === 'password') {
        field.type = 'text';
        active.style.display = 'block';
        hidden.style.display = 'none';
      } else {
        field.type = 'password';
        active.style.display = 'none';
        hidden.style.display = 'block';
      }
    });
  });
}

/*
// Forgot PW Functions
 */
async function submitForgotPassword(e) {
  if (forgotPwCheckComplete === true) return;

  e && e.preventDefault();

  toggleSpinner();

  const form = navbarForgotPasswordForm,
    email = form.querySelector("input[name='email']").value,
    errDiv = form.querySelector('#forgotPwErrMsg'),
    submitButton = form.querySelector("button[name='submit_btn']"),
    submitButtonTxt = submitButton.innerText;

  errDiv.innerHTML = '';
  errDiv.classList.remove('error');

  submitButton.innerHTML = 'Please wait&hellip;';
  submitButton.disabled = true;
  submitButton.classList.add('disabled');

  function resetSubmitButton() {
    submitButton.innerText = submitButtonTxt;
    submitButton.disabled = false;
    submitButton.classList.remove('disabled');
  }
  const valid = validateForm(form);

  if (valid) {
    const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

    smFunc
      .getJson('/app/auth/process/?do=requestResetPassword', 'POST', {
        email,
        recaptchaToken,
      })
      .then((data) => {
        if (data.result === 'success') {
          if (data.action === 'ACCOUNT-SELECTION') {
            const accountSelectorDiv = document.querySelector(
              '.js-accounts-selector-forgot'
            );
            const accountSelectorAccountsDiv = document.querySelector(
              '.js-accounts-selector-accounts-forgot'
            );
            const regularLoginDiv = document.querySelectorAll(
              '.js-password-recovery-field'
            );

            //Clear html of accountSelectorAccountsDiv
            accountSelectorAccountsDiv.innerHTML = '';
            //insert into account_selector div 2 divs, one for each account
            data.accounts.forEach((account, index) => {
              const accountDiv = document.createElement('div');
              accountDiv.classList.add('account');
              accountDiv.innerHTML = `<input type="radio" name="account" value="${index}" id="account_${index}" class="social-account-radio">
                <label for="account_${index}" class="social-account-label">${account.username}</label>`;

              // Populate div
              accountSelectorAccountsDiv.appendChild(accountDiv);

              // Show account and hide the regular username/pass inputs
              accountSelectorDiv.classList.add('show');
              accountSelectorAccountsDiv.classList.add('show');
              accountSelectorDiv.classList.remove('d-none');
              regularLoginDiv.forEach((el) => {
                el.classList.add('d-none');
              });

              //Add event listener to each radio button
              accountDiv
                .querySelector('input')
                .addEventListener('change', (e) => {
                  // document.querySelector('input[name="selected_account"]').value = e.target.value;
                  if (accountSelector(e.target.value, '')) {
                    console.log('returned');
                    forgotPwCheckComplete = true;
                    const tab2 = document.querySelector('.js-reset.step2');
                    tab2.querySelector('.tab-text-email').textContent = email;
                    document
                      .querySelector('.js-reset.step1')
                      .classList.remove('active');
                    tab2.classList.add('active');
                  }
                });
            });
          } else {
            forgotPwCheckComplete = true;
            const tab2 = document.querySelector('.js-reset.step2');
            tab2.querySelector('.tab-text-email').textContent = email;
            document
              .querySelector('.js-reset.step1')
              .classList.remove('active');
            tab2.classList.add('active');
          }
        } else if (data.result === 'fail') {
          showSystemError(errDiv, data.reason);
          resetSubmitButton();
        }
      })
      .catch((e) => {
        showSystemError(errDiv, 'Error processing form');
        resetSubmitButton();
        console.error(e);
      })
      .finally(() => {
        toggleSpinner(false);
        resetSubmitButton();
      });
  } else {
    toggleSpinner(false);
    resetSubmitButton();
  }
}

export function forgotPwOpenTab() {
  const tab1 = document.querySelector('.js-reset.step1');
  const tab2 = document.querySelector('.js-reset.step2');

  tab1.classList.add('active');
  tab2.classList.remove('active');

  switchActiveTab(forgotTab);
}

/*
// Social Login Functions
 */
function handleGoogleSignInResponse(response) {
  toggleSpinner();
  socialSuccessLoginCallback(response.credential, 'google');
}

function googleSocialLogin() {
  sendGAevent('ext-cta', 'social-google', window.location.pathname);
}

async function facebookSocialLogin() {
  await loadFacebookAuth();

  toggleSpinner();
  FB.getLoginStatus(
    function (e) {
      if (e.status === 'connected') {
        const curTime = Math.round(new Date().getTime() / 1000);
        const expiresTime = e.authResponse?.data_access_expiration_time || 0;

        if (expiresTime - curTime >= 0) {
          socialSuccessLoginCallback(e.authResponse.accessToken, 'facebook');
          return;
        }
      }

      FB.login(
        (el) => {
          if (el.authResponse) {
            socialSuccessLoginCallback(el.authResponse.accessToken, 'facebook');
          } else {
            loginOpenTab();
            const errDiv = document.querySelector('#loginAccountErrMsg');
            errDiv.innerHTML =
              'An error occurred. Please try again or choose another method of registration.';
            errDiv.classList.add('error');
            toggleSpinner(false);
          }
        },
        { scope: 'email' }
      );
    },
    { force: 'true' }
  );
}

async function appleSocialLogin() {
  await loadAppleAuth();

  toggleSpinner();
  AppleID.auth.signIn();
}

async function accountSelector(index, redirect, target) {
  let url = '';
  const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);
  if (target === 'socialLogin') {
    url = '/app/auth/process/?do=socialLoginSelector';
  } else {
    url = '/app/auth/process/?do=requestResetPassword';
  }
  smFunc
    .getJson(url, 'POST', {
      index: index,
      redirect,
      recaptchaToken,
    })
    .then((data) => {
      if (data.result === 'success') {
        return data;
      } else {
        throw 'fail';
      }
    })
    .then((data) => {
      if (data.action === 'LOGIN') {
        redirectPage(redirect, data.tr_access);
      } else {
        return data;
      }
    });
}

async function socialSuccessLoginCallback(result, social) {
  const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

  const form = document.getElementById('navbar_loginform'),
    errDiv = form.querySelector('#loginAccountErrMsg'),
    redirect = form.querySelector("input[name='login_redirect']").value,
    popup = document.getElementById('join');

  smFunc
    .getJson('/app/auth/process/?do=socialLogin', 'POST', {
      method: social,
      code: result,
      recaptchaToken,
      redirect,
    })
    .then((data) => {
      if (data.result === 'success') {
        return data;
      } else {
        throw 'fail';
      }
    })
    .then((data) => {
      if (data.action === 'LOGIN') {
        redirectPage(redirect, data.tr_access);
      } else if (data.action === 'REGISTER') {
        if (!popup.classList.contains('on')) {
          modalOn(popup);
        }
        registerOpenTab();

        const regForm = document.getElementById('navbar_regform'),
          email = regForm.querySelector("input[name='email']"),
          username = regForm.querySelector("input[name='username']"),
          fullname = regForm.querySelector("input[name='fullname']"),
          password = regForm.querySelector("input[name='password']"),
          avatar = regForm.querySelector("input[name='avatar']"),
          socialEl = regForm.querySelector("input[name='social']"),
          socialButtons = document.querySelectorAll('.social-signin'),
          ios = regForm.querySelector("input[name='ios']");

        regForm.classList.add('is-social');

        // disable email
        email.value = data.data.email;
        email.disabled = true;

        fullname.value = `${data.data.name || ''} ${
          data.data.familyName || ''
        }`.trim();

        // hide password
        password.value = randomString();
        password.parentElement.parentElement.style.display = 'none';

        avatar.value = data.data.avatar;
        ios.value = data.data.iosToken || '';
        socialEl.value = social;

        socialButtons.forEach((item) => (item.style.display = 'none'));
        username.focus();
      } else if (data.action === 'ACCOUNT-SELECTION') {
        if (data.accounts) {
          const accountSelectorDiv = document.querySelector(
            '.js-accounts-selector'
          );
          const accountSelectorAccountsDiv = document.querySelector(
            '.js-accounts-selector-accounts'
          );
          const regularLoginDiv =
            document.querySelectorAll('.js-standard-login');

          //Clear html of accountSelectorAccountsDiv
          accountSelectorAccountsDiv.innerHTML = '';
          //insert into account_selector div 2 divs, one for each account
          data.accounts.forEach((account, index) => {
            const accountDiv = document.createElement('div');
            accountDiv.classList.add('account');
            accountDiv.innerHTML = `<input type="radio" name="account" value="${index}" id="account_${index}" class="social-account-radio">
                <label for="account_${index}" class="social-account-label">${account.username}</label>`;

            // Populate div
            accountSelectorAccountsDiv.appendChild(accountDiv);

            // Show account and hide the regular username/pass inputs
            accountSelectorAccountsDiv.classList.add('show');
            accountSelectorDiv.classList.add('show');
            accountSelectorDiv.classList.remove('d-none');
            regularLoginDiv.forEach((el) => {
              el.classList.add('hidden');
            });

            //Add event listener to each radio button
            accountDiv
              .querySelector('input')
              .addEventListener('change', (e) => {
                // document.querySelector('input[name="selected_account"]').value = e.target.value;
                accountSelector(e.target.value, redirect, 'socialLogin');
              });
          });
        }
      }
    })
    .catch((e) => {
      console.error(e);
      loginOpenTab();
      errDiv.innerHTML =
        'An error occurred. Please try again or choose another method of registration.';
      errDiv.classList.add('error');
    })
    .finally(() => {
      toggleSpinner(false);
    });
}

// Initialisation
((e) => {
  'loading' != document.readyState
    ? e()
    : document.addEventListener('DOMContentLoaded', e);
})(() => {
  if (isUserLoggedIn()) {
    const q = new URLSearchParams(window.location.search);

    if (q && q.has('traccess')) {
      fetchAndRedirectTR();
    }
  } else {
    window.location.href.match('#login')
      ? (modalOn(popup), loginOpenTab())
      : (window.location.href.match('#register') ||
          window.location.href.match('#join')) &&
        (modalOn(popup), registerOpenTab());
  }
});

// Code for the home page onboarding type questions
document
  .querySelectorAll('.js-landing-questions-brokerage')
  .forEach((radio) => {
    radio.addEventListener('change', (e) => {
      const futureQuestionsDiv = document.querySelector(
        '.js-landing-questions-future'
      );
      const whichBrokerDiv = document.querySelector(
        '.js-landing-questions-which-broker'
      );

      futureQuestionsDiv.classList.remove('d-none');
      whichBrokerDiv.classList.remove('d-none');

      if (e.currentTarget.value === 'yes') {
        futureQuestionsDiv.classList.add('d-none');
      } else if (e.currentTarget.value === 'no') {
        whichBrokerDiv.classList.add('d-none');
      }
    });
  });

// Questions submission and nav
const step1 = document.querySelector('.js-landing-questions-step1');
const step2 = document.querySelector('.js-landing-questions-step2');
const complete = document.querySelector('.js-landing-questions-complete');
const stepCounter = document.querySelector('.js-landing-success-steps');
const stepCounterCurrent = stepCounter.querySelector(
  '.js-landing-success-steps-current'
);

new NiceSelect(step1.querySelector('[name="trading_experience"]'), {
  placeholder: 'Select your experience level...',
});
new NiceSelect(step1.querySelector('[name="current_broker"]'), {
  placeholder: 'Select your broker...',
});
new NiceSelect(step1.querySelector('[name="trading_status"]'), {
  placeholder: 'Select your trading status...',
});

new NiceSelect(step2.querySelector('[name="primary_goal"]'), {
  placeholder: 'Select your primary goal for trading...',
});

new NiceSelect(step2.querySelector('[name="learning_preference"]'), {
  placeholder: 'Select how you prefer to learn about trading...',
});

new NiceSelect(step2.querySelector('[name="risk_tolerance"]'), {
  placeholder: 'Select your risk tolerance...',
});

step1.addEventListener('submit', (e) => {
  e.preventDefault();

  const form = e.currentTarget;
  const errorMessage = form.querySelector('.js-landing-questions-step1-error');

  // form validation (check required are filled)
  const valid = validateForm(form);

  if (valid) {
    const formData = new FormData(form);

    const formAnswers = {};
    formData.forEach((value, key) => {
      formAnswers[key] = value;
    });

    smFunc
      .getJson('/app/onboarding/process/?do=step1', 'POST', formAnswers)
      .then((data) => {
        if (data.result === 'success') {
          step1.classList.add('d-none');
          step2.classList.remove('d-none');
          stepCounter.children[0].children[
            stepCounter.children[0].children.length - 1
          ].classList.add('active');
          stepCounterCurrent.innerText = 3;
        } else if (data.result === 'fail') {
          showSystemError(errorMessage, data.reason);
        }
      })
      .catch((err) => {
        showSystemError(
          errorMessage,
          'Error saving question answers to database'
        );
        console.error(err);
      })
      .finally(() => {
        toggleSpinner(false);
      });
  } else {
    toggleSpinner(false);
  }
});

step2.addEventListener('submit', (e) => {
  e.preventDefault();
  toggleSpinner();

  const form = e.currentTarget;
  const errorMessage = form.querySelector('.js-landing-questions-step2-error');

  // form validation (check required are filled)
  const valid = validateForm(form);

  if (valid) {
    const formData = new FormData(form);

    const formAnswers = {};
    formData.forEach((value, key) => {
      formAnswers[key] = value;
    });

    smFunc
      .getJson('/app/onboarding/process/?do=step2', 'POST', formAnswers)
      .then((data) => {
        if (data.result === 'success') {
          step2.classList.add('d-none');
          complete.classList.remove('d-none');
          stepCounter.classList.add('d-none');
          setTimeout(() => {
            modalOff(popup, registerCheckComplete);
            window.location.href = '/';
          }, 3000);
        } else if (data.result === 'fail') {
          showSystemError(errorMessage, data.reason);
        }
      })
      .catch((err) => {
        showSystemError(
          errorMessage,
          'Error saving question answers to database'
        );
        console.error(err);
      })
      .finally(() => {
        toggleSpinner(false);
      });
  } else {
    toggleSpinner(false);
  }
});

document
  .querySelector('.js-landing-questions-step2-back')
  .addEventListener('click', () => {
    step1.classList.remove('d-none');
    step2.classList.add('d-none');
    stepCounter.children[0].children[
      stepCounter.children[0].children.length - 1
    ].classList.remove('active');
    stepCounterCurrent.innerText = 2;
  });

// testing script to open questions - disable for golive
document.querySelector('.js-register').classList.remove('active');
document.querySelector('.js-landing-success').classList.add('active');
document.querySelector('.js-landing-success-icon').classList.add('d-none');
document.querySelector('.landing-success-type_questions').style.display =
  'block';
