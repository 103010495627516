import { validateForm } from '@/helpers/form-validation';
import { processEmailCapture } from '@/core/thinkhuge';
import ModalHandler from '@/core/ModalHandler.js';
import { modalOn } from '@/helpers/form-dom';

// These buttons are on the main nav and used in quite a few places, lets keep this in one single place rather than having to define it inside section/js-file.js
const newsLetterPopUpBtns = document.querySelectorAll(
  '.js-newsletter-modal-trigger'
);
const newsletterModalHandler = new ModalHandler('.js-modal-newsletter');

if (newsLetterPopUpBtns.length > 0) {
  newsLetterPopUpBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      const modalText = document.querySelector(
        '.js-modal-newsletter-container'
      );
      modalText.classList.remove('d-none');

      newsletterModalHandler.showModal();
      const newsletterModal = document.querySelector('.js-modal-newsletter');
      newsletterModal
        .querySelector('.js-email-grabber-generic-form')
        .classList.remove('hidden');
      newsletterModal
        .querySelector('.js-email-grabber-generic-success')
        .classList.add('hidden');
      newsletterModal
        .querySelector('.js-email-grabber-generic-success')
        .classList.remove('successAnimateOut');
    });
  });
}

function newsletterSignup(
  form,
  source,
  customAP = false,
  newsletterModalHandler
) {
  const email = form.email.value;
  const valid = validateForm(form);

  if (valid) {
    processEmailCapture(email, source, customAP);
    const success = form.nextElementSibling;
    const modalText = document.querySelector('.js-modal-newsletter-container');
    success.classList.remove('hidden');
    form.classList.add('hidden');
    modalText.classList.add('d-none');

    // this logic is for modal pop up
    const newsletterContainer = document.querySelector(
      '.js-modal-newsletter.on'
    );
    if (newsletterContainer) {
      setTimeout(() => {
        newsletterModalHandler.hideModal();
      }, 5000);
    }
    // end of modal pop up logic

    setTimeout(() => {
      success.classList.add('successAnimateOut');
    }, 4000);
  }
}

function newsletterRegSignup(form, source, customAP = false) {
  const email = form.email.value;
  const valid = validateForm(form);
  const popup = document.querySelector('.js-popup');
  const registerHeading = popup.querySelector('.js-popup-register-heading');
  const registerSubheading = popup.querySelector(
    '.js-popup-register-subheading'
  );
  const socialSeparatorText = document.querySelector(
    '.js-social-separator-text'
  );
  const registerForm = popup.querySelector('.js-register-form');
  const exitPopups = document.querySelectorAll('.js-exit-popup');
  const registerPopup = document.querySelectorAll('.js-popup');

  const registerTab = popup.querySelector('.js-register');
  const loginTab = popup.querySelector('.js-login');
  const landingSuccessTab = popup.querySelector('.js-landing-success');
  const forgotTab = popup.querySelector('.js-forgot');

  const allTabs = [registerTab, loginTab, landingSuccessTab, forgotTab];

  if (valid) {
    // save email to db
    processEmailCapture(email, source, customAP);

    // open registration form but set flags to use the special flow with questions and no redirect after reg
    // If there are multiple exit popups, iterate over them and close each one if it's open

    exitPopups.forEach((popup) => {
      if (popup.classList.contains('on')) {
        const successVideoObject = document.querySelector(
          '.js-success-video-object'
        );

        if (successVideoObject !== null) {
          successVideoObject.contentWindow.postMessage(
            '{"method":"pause"}',
            '*'
          );
        }

        httApp.isAnyPopupActive = false;
        setTimeout(() => document.body.classList.remove('modal-open'), 50);
        setTimeout(() => popup.classList.remove('on'), 25);
      }
    });

    registerTab.querySelector('.form-input.email.js-registration-email').value =
      email;

    modalOn(registerPopup[0]);

    const { action } = event.target.dataset;

    socialSeparatorText.innerText = 'Or register using';

    popup.classList.add('hide-right');
    popup.classList.remove('hide-steps');

    registerHeading.innerText = 'Welcome To The How To Trade Community!';
    registerSubheading.innerText =
      'Answer a few questions to receive relevant content, news and opportunities.';

    registerForm.setAttribute('data-action', action);
    registerForm.setAttribute('data-redirect', false);

    registerTab.querySelectorAll('.js-register-link').forEach((element) => {
      element.setAttribute('data-action', action);
      element.setAttribute('data-redirect', false);
    });

    loginTab.querySelectorAll('.js-register-link').forEach((element) => {
      element.setAttribute('data-action', action);
      element.setAttribute('data-redirect', false);
    });

    for (const tab of allTabs) {
      if (tab === registerTab) continue;

      tab.classList.toggle('active', false);
    }

    if (registerTab) registerTab.classList.toggle('active', true);
  }
}

// I believe this code is now redundant, was from the B version of the wp-sidebar, keeping it here though just incase we need to implement something similar
document
  .querySelectorAll('.js-sidebar-email, .js-email-grabber-signup')
  .forEach((element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      const form = e.target;
      const src = form.querySelector('input[name="source"]')?.value || false;
      const ap =
        form.querySelector('input[name="autopilotJourney"]')?.value || false;
      newsletterSignup(form, src, ap);
    });
  });

// This is used on functions.tpl email grabber for newsletter signup
document
  .querySelectorAll('.js-email-grabber-generic-form')
  .forEach((element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      const form = e.target;
      const src = form.querySelector('.js-email-grabber-btn').dataset.source;
      const ap =
        form.querySelector('input[name="autopilotJourney"]')?.value || false;
      newsletterSignup(form, src, ap, newsletterModalHandler);
    });
  });

document
  .querySelectorAll('.js-newsletter-questions-reg-form')
  .forEach((element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      const form = e.target;
      const src = form.querySelector('.js-newsletter-questions-reg-btn').dataset
        .source;
      const ap =
        form.querySelector('input[name="autopilotJourney"]')?.value || false;
      newsletterRegSignup(form, src, ap);
    });
  });
