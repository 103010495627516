// import code for every page

import { loadSvg } from '@/core/utils';
import '@/helpers/revealAnimations';
import '@/helpers/smoothScroll';
// import '@/helpers/glightbox';
import '@/helpers/freshchat';
import '@/helpers/date';
import '@/helpers/eventLog';
import '@/helpers/wp-reg';
import '@/helpers/lightboxImages';
import '@/components/cookieConsent';
import '@/components/webinarCountdown';
import '@/components/webinarGrabEmail';
import '@/components/accessTraderoom';
import '@/components/socialProof';
import { vimeoPlaceholderClickHandler } from '@/core/utils';
import { lazyLoadVideo } from '@/helpers/dom';
import '@/core/traderoom';
import '@/components/heroEmailSignup';
import FormManager from '@/core/FormManager';
import { Countdown } from '@/core/Countdown';

// Trading Competition countdown logic - need this globally - was issue when logged in and promo ribbon was not appearing,
if (document.querySelector('.js-competition-countdown')) {
  const nextContest = httApp.contest_start_ts;
  const countdownTitle = '';
  const countdownContainer = '';

  const onCountdownFinish = () => {
    if (countdownTitle && countdownContainer) {
      countdownTitle.style.display = 'none';
      countdownContainer.style.display = 'none';
    }
  };

  new Countdown('.js-competition-countdown', nextContest, {
    onFinish: onCountdownFinish,
    hideSeconds: false,
    useDays: true,
  });
}
// End Trading Competition countdown logic

// load svg sprite

document.addEventListener('DOMContentLoaded', () => {
  loadSvg(httApp.svgSpriteUrl);
});

export const formManager = new FormManager();

// vimeo placeholders

document.querySelectorAll('.js-vimeo-placeholder').forEach((element) => {
  element.addEventListener('click', vimeoPlaceholderClickHandler);
});

// expand mobile nav accordion

document.querySelectorAll('.js-mobile-menu-expand').forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.toggle('active');
    const items = button.nextElementSibling;

    items.style.maxHeight = button.classList.contains('active')
      ? `1500px`
      : '0px';
  });
});

// load hero video

document.querySelectorAll('.js-hero-video').forEach((video) => {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      lazyLoadVideo(video);
    }, 500);
  });

  // if (window.innerWidth > 991) {
  // }
});

const timestampsToLocal = document.querySelectorAll('.js-timestamp-to-local');

timestampsToLocal.forEach((el) => {
  const timestamp = new Date(parseInt(el.dataset.timestamp) * 1000);

  // Calculate the local date by subtracting the offset from the UTC date
  const localDate = new Date(timestamp);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'Octboer',
    'November',
    'December',
  ];

  const month = localDate.getMonth();
  const monthText = monthNames[month];
  const dayOfMonth = localDate.getDate();

  const lastDigit = dayOfMonth % 10;
  const lastTwoDigits = dayOfMonth % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th';
  }
  let ordinalSuffix;
  switch (lastDigit) {
    case 1:
      ordinalSuffix = 'st';
      break;
    case 2:
      ordinalSuffix = 'nd';
      break;
    case 3:
      ordinalSuffix = 'rd';
      break;
    default:
      ordinalSuffix = 'th';
  }

  // Extract hours, minutes, and AM/PM format
  let hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12; // Convert to 12-hour format
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes

  const formattedTime = `${monthText} ${dayOfMonth}${ordinalSuffix} at ${hours}:${minutesFormatted} ${ampm}`;

  el.innerText = formattedTime;
});
